import React from 'react';
import { connectCart } from 'ui/util/connect-cart';
import { Cart } from 'ui/component/cart';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartSummaryData } from '@silkpwa/magento/api/cart-repository/util';
import { ICartItem } from 'ui/util/select-cart-items';

export interface ICartPageProps {
    cartItems: Array<ICartItem>;
    cartSummaryData: Array<ICartSummaryData>;
    cartCheckoutDisabled: boolean;
    cartFreeShippingProgressBar: string;
}

const CartPageImpl: React.FC<ICartPageProps> = ({
    cartItems,
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
}) => {
    const t = usePhraseTranslater();
    const designConfig = useDesignConfig();
    const documentTitle = `${designConfig.pageTitle} ${t('Cart')}`;
    return (
        <MainColumn className="maincolumn-cartpage">
            <DocumentTitle>{documentTitle}</DocumentTitle>
            <Cart
                cartItems={cartItems}
                cartSummaryData={cartSummaryData}
                cartCheckoutDisabled={cartCheckoutDisabled}
                cartFreeShippingProgressBar={cartFreeShippingProgressBar}
            />
            <CertonaRecommendations />
        </MainColumn>
    );
};

const CartPage = connectCart(CartPageImpl);

export { CartPage as default };
