import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import EmbroideryIcon from 'assets/images/mockImgs/EmbroideryIcon.png';
import closedArrow from 'assets/images/mockImgs/rightArrow.png';
import openedArrow from 'assets/images/mockImgs/downArrow.png';
import { EmbroideryItem } from './embroidery-item';
import { ItemPrice } from '../item-price';
import styles from './style.css';

export const EmbroideryItems = ({ item }) => {
    if (item.embroidery_items.length <= 0) return null;

    return (
        <UseState initialState={false}>
            {([opened, setOpened]) => (
                <div className={styles.cartItemFoot}>
                    <AccessibleButton
                        tag="div"
                        className={styles.footExpand}
                        data-test={`product-options-${item.sku}`}
                        action={() => setOpened(!opened)}
                    >
                        <img className={styles.EmbroideryIcon} src={EmbroideryIcon} alt="" />
                        <span className={styles.expandText}>Product Options</span>
                        {' '}
                        <ItemPrice price={item.optionIncrement} before="+" className={styles.expandPrice} />
                        {!opened && <img className={styles.closedArrow} src={closedArrow} alt="" />}
                        {opened && <img className={styles.openedArrow} src={openedArrow} alt="" />}
                    </AccessibleButton>
                    {opened && (
                        <ul data-test={`product-options-list-${item.sku}`} className={styles.embroideryItems}>
                            {item.embroidery_items.map(i => (
                                <EmbroideryItem key={i.item_id} item={i} />
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </UseState>
    );
};
